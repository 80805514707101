.home{
	.container-home{
		max-width: 970px;
		margin: auto;
	}
	.home-txt{
		text-align: center;
		font-size: 16px;
		padding: 20px 0 40px;
		line-height: 23px;
	}
	.search-control{
		background: transparent;
		padding: 0;
		.form-group{
			margin-bottom: 23px;
		}
	}
	.modal-dialog{
		margin-top: 120px;
	}
}
.main{
	overflow: hidden;
}