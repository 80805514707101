.navbar-default{
	background: none;
	border: none;
	min-height: auto;
	margin-bottom: 0;
}

.form-control{
	border: none;
	@include border-radius(0);
}
.btn{
	border: 2px solid $secondary;
	padding: 15px 40px;
	font-size: 1rem;
	text-transform: uppercase;
	@include border-radius(0px);
	position: relative;
	color: white;
	background: transparent;
	&:after{
		position: absolute;
		width: 0;
		content: "";
		background: $secondary;
		height: 100%;
		left: 0;
		top: 0;
		z-index: -1;
		@include transition(0.3s);
	}
	&:hover, &:focus, &.focus, &:active{
		&:after{
			width: 100%;
		}
	}
	@include xs{
		width: 100%;
	}
}
.btn-default{
	color: white;
}
.btn-primary{
	background: $red-dark;
	color: white;
	padding: 12px 70px;
	font-size: 15px;
	border: none;
	@include border-radius(0px);
	@include fs(18);
	text-transform: none;
	&:hover, &:focus, &.focus, &:active{
		background: $red-dark;
		border: none;
	}
}
.btn-link {
    background: transparent;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: 0 0 5px;
    padding-top: 0px;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
}
.form-control{
	border: none;
	@include border-radius(0);
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus{
	background: none;
}
.slick-initialized .slick-slide{
	display: inline-block;
	float: none;
	vertical-align: middle;
}
.hero-banner{
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	@include xs{
		min-height: 400px;
	}
	> img{
		opacity: 0;
	}
	.container{
		position: absolute;
		top: 50%;
		left: 50%;
		@include translate2D(-50%, -50%);
		.banner-content{
			max-width: 400px;
			.title{
				font-weight: 300;
				text-transform: uppercase;
			}
			.description{
				@include fs(20);
				font-weight: 300;
				margin-bottom: 30px;
				margin-top: 20px;
			}
		}
	}
}
.category-container{
	padding: 70px 0;
	.title{
		text-align: center;
		font-weight: 300;
		text-transform: uppercase;
	}
	.btn-show-more{
		border: 1px solid white;
		margin: 55px auto;
	}
}
.product-category{
	margin-top: 25px;
	font-weight: 300;
	display: block;
	padding: 20px 10px;
	.product-info{
		.product-name{
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 25px;
			font-weight: 300;
			line-height: 3rem;
		}
		.img-content{
			display: block;
			position: relative;
			img{
				position: relative;
			    top: 50%;
			    transform: translateY(-50%);
			}
		}
		.model{
			@include fs(24);
			text-transform: uppercase;
			margin-top: 50px;
		}
		.description{
			@include fs(18);
			margin-top: 20px;
		}
	}
	.btn{
		width: 100%;
	}
	.list-function{
		border-top: 1px solid #333;
		margin-top: 35px;
		padding: 20px 0;
		li{
			@include fs(18);
			padding: 8px 0;
		}
	}
}
.back-to-top{
	position: absolute;
    top: -20px;
    width: 60px;
    height: 40px;
    right: 0;
    padding: 0 10px;
    cursor: pointer;
    background: $gray-darker2;
    @include device{
    	right: calc(50% - 30px);
    }
    &:after{
    	content: "";
    	width: 14px;
    	height: 14px;
    	border-left: 2px solid $red;
    	border-top: 2px solid $red;
    	display: block;
    	@include rotate(45deg);
    	margin-left: 13px;
    	margin-top: -24px;
    }
    &:before{
    	content: "";
    	width: 40px;
    	height: 40px;
    	border-radius: 50%;
    	border: 2px solid $red;
    	display: block;
    }
}
