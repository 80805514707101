footer {
	background:$gray-darker2;
	color: $text-color;
	width: 100%;
	.container{
		border-top: 1px solid rgba(103,106,108,0.5);
		padding-top: 30px;
		padding-bottom: 20px;
		@include desktop{
			padding: 60px 0 40px;
		}
		.description{
			font-weight: 600;
			margin-bottom: 0;
			margin-top: 40px;
			@include fs(16);
		}
		.phone{
			@include fs(56);
			font-weight: 300;
			color: $red;
		}
		.list{
			li{
				color: $gray;
				margin-bottom: 5px;
			}
		}
		.list-social{
			li{
				margin-bottom: 20px;
			}
			a{
				font-weight: 600;
				text-transform: uppercase;
				color: $gray-dark;
				vertical-align: top;
				@include fs(16);
				i{
					margin-right: 10px;
					color: white;
					@include fs(20);
				}
			}
		}
		.bottom{
			color: $gray-dark;
			padding-top: 30px;
			margin-top: 40px;
			border-top: 1px solid rgba(103,106,108,0.5);
			font-weight: 300;
			@include fs(16);
			font-family: Tahoma;
			position: relative;
		}
		h5{
			@include fs(16);
			text-transform: uppercase;
		}
		.list{
			li{
				@include fs(16);
			}
		}
	}
}
