header{
	background: $darker;
	height: 77px;
	@include xs{
		height: 54px;
		background: transparent;
	}
	.navbar{
		z-index: 100;
		@include device{
			background: $darker;
		}
		@include xs{
			margin-left: 0px;
		}
		.container{
			position: relative;
		}
	}
	.navbar-brand{
		height: auto;
		margin-right: 43px;
		padding-left: 0;
		padding-top: 12px;
		@include xs{
			float: none;
			margin: auto;
			width: 73px;
			display: block;
			padding-bottom: 5px;
		}
		img{
			max-width: 84px;
		}
	}
	.navbar-toggle{
		float: left;
		@include xs{
			background: transparent;
		    border: medium none!important;
		    margin: 0;
		    padding: 20px 15px;
		    &:hover, &:focus{
		    	background: white!important;
		    }
		}
	}
	.search-icon{
		background: url("../images/icon/search-icon.png") no-repeat center;
		@include size(21px);
		margin-top: 18px;
		margin-right: 13px;
		background-size: cover;
	}
	.navbar-collapse{
		padding-top: 8px;
		.navbar-nav {
			width: 100%;
			@include xs{
				margin: 0;
				margin-left: 15px;
			}
			.nav-item {
				position: static;
				.nav-link{
					color: #C3C4C7;
					@include fs(17);
					font-weight: 300;
					position: relative;
					@include device{
						font-size: 1.2rem;
					}
					@include xs{
						padding-left: 0;
					}
					&:after{
						content: "";
						@include size(6px);
						border-right: 1px solid $gray-dark;
						border-bottom: 1px solid $gray-dark;
						border-top: none;
						border-left: none;
						@include rotate(45deg);
						margin-left: 8px;
						@include transition(0.3s);
						position: absolute;
						top: 14px;
						@include device{
							left: auto;
							right: 30px;
						}
					}
					&.active:after{
						@include rotate(-135deg);
						top: 16px;
					}
					&.active:before{
						display: inline-block;
					    width: 0;
					    height: 0;
					    margin-left: 0.255em;
					    vertical-align: 0.255em;
					    content: "";
					    border-bottom: 8px solid white;
					    border-right: 8px solid transparent;
					    border-top: 0;
					    border-left: 8px solid transparent;
					    position: absolute;
					    top: 36px;
					    left: 50%;
					}
					&:hover, &:focus, &:visited{
						color: white;
						opacity: 1;
					}
				}
				img{
					width: 24px;
				}
				&.dropdown-language{
					.dropdown-menu{
						width: 70px;
						min-width: 70px;
						right: 0;
						left: auto;
						padding: 10px 0;
						img{
							width: 24px;
							top: 0;
	    					transform: none;
						}
					}
				}
			}
			.dropdown-menu{
				width: 100%;
				border: none;
				padding: 40px;
				@include xs{
					margin: 0 -15px;
					li a:hover{
						background: $white!important;
						font-weight: bold;
					}
	    		}
	    		.dropdown-item{
	    			.img-content{
	    				position: relative;
	    			}
	    			img{
	    				max-width: 120px;
	    				display: block;
	    				margin: auto;
	    				width: 100%;
	    				position: relative;
	    				top: 50%;
	    				left: 50%;
	    				@include translate2D(-50%, -50%);
	    			}
	    			p{
	    				text-align: center;
	    				margin-top: 20px;
	    				text-transform: uppercase;
	    				font-weight: 600;
	    				white-space: normal;
	    			}
	    			&:hover p{
	    				color: $red;
	    			}
	    		}
			}
		}
	}
}