.product-detail{
	padding: 60px 0;
	.product-intro{
		padding-bottom: 70px;
		@include device{
			padding-bottom: 35px;
		}
		.col-lg-5{
			@include device{
				order: 1;
			}
		}
	}
	.product-info{
		.title{
			font-weight: 300;
			text-transform: uppercase;
			padding-bottom: 20px;
			border-bottom: 2px solid white;
			margin-bottom: 20px;
			@include fs(38);
		}
		.product-dimensions{
			margin: 55px 0 45px;
			display: flex;
			@include device{
				display: none;
			}
			.node {
			    font-size: 2.8rem;
			    color: $gray-darker;
			    font-weight: 300;
			    &.value{
			    	color: white;
			    }
			    &.separator{
			    	font-size: 1.8rem;
			    	text-transform: uppercase;
			    	line-height: 4.3rem;
			    	margin: 0 20px;
			    }
			    &.end{
			    	margin-left: 20px;
			    	small{
			    		color: $gray-darker;
			    	}
			    }
			    small{
			    	font-weight: 300;
			    	font-size:1.5rem;
					color: $gray-dark;
					text-transform: uppercase;
			    }
			}
		}
		.description{
			p{
				@include fs(20);
				font-weight: 300;
			}
		}

	}
	.product-gallery{
		margin-bottom: 45px;
	}
	.product-navigation{
		.container{
			padding: 0;
		}
		.product-navigation-list{
			margin: 0 0 35px;
			display: flex;
			align-items: flex-end;
			li{
				margin: 0 30px;
				&:first-child{
					margin-left: 0;
				}
				> .btn-link{
					padding-top: 10px;
					position: relative;
					&:after{
						content: "";
						width: 100%;
						height: 2px;
						background: $secondary;
						left: 0;
						margin-top: 5px;
						display: block;
					}
					&:hover{
						color: white;
						text-decoration: none;
						&:after{
							animation: slideInLeft 0.5s;
						}
					}
				}
			}
		}
		&.anchored{
			position: fixed;
			top: 0;
			left: 0;
			z-index: 100;
			background: black;
			width: 100%;
			padding-top: 20px;
			.product-navigation-list{
				margin-bottom: 10px;
			}
			.container{
				padding: 0 15px;
			}
		}
	}
	.features{
		.benefits-slider{
			background: #000;
			.container{
				padding: 0;
			}
			.slider-detail{
				position: relative;
				padding: 120px 0 90px;
				@include device{
					padding: 60px 0;
				}
				.slick-slide{
					padding:0 15px;
				}
				.slide-details-content{
					@include desktop{
						position: absolute;
						display: block;
						top: 50%;
						@include translateY(-50%);
					}
					.slide-header {
					    display: block;
					    margin: 0 0 30px;
					    padding: 0 20px 30px 0;
					    text-transform: uppercase;
					    font-weight: 300;
					    line-height: 40px;
					    border-bottom: 1px solid white;
					    @include device{
					    	margin-top: 30px;
					    	@include fs(22);
					    	line-height: 35px;
					    }
					}
					.slide-body{
						@include fs(18);
						font-weight: 300;
						padding-right: 15px;
						@include xs{
							padding-right: 0;
						}
					}
				}
				.slide-details{
					min-height: 360px;
				}
			}
		}
		.key-features-holder{
			padding: 35px 0 120px;
			background: #000;
			@include device{
				padding: 30px 0 20px;
			}
			.key-features-list{
				border-top: 1px solid rgba(103,106,108,0.5);
				padding-top: 35px;
			}
			ul{
				margin-left: -15px;
				margin-right: -15px;
				li {
					p{
						border-top: 1px solid rgba(103,106,108,0.5);
						color: #fff;
						font-weight: bold;
						line-height: 1.5;
						padding: 25px 0 17px;
						text-align: center;
						@include fs(19);
						@include device{
							@include fs(17);
							padding: 22px 0 10px;
						}
					}
					&:nth-child(-n+3) p{
						@include desktop{
							border-top: none;
						}
					}
					&:first-child p{
						border-top: none;
					}
				}
			}
		}
		.video-wrapper-block {
		    margin-bottom: 80px;
		    margin-top: 120px;
		    @include device{
		    	margin-bottom: 40px;
		    	margin-top: 60px;
		    }
	    	.video-cover{
	    		position: relative;
	    		.video-cover-image{
	    			img{
	    				opacity: 0;
	    				max-width: 100%;
	    			}
	    		}
	    		.icon-play{
	    			@include size(86px);
	    			border: 2px solid white;
	    			@include border-radius(50%);
	    			position: absolute;
	    			top: calc(50% - 86px);
	    			left: calc(50% - 43px);
	    			cursor: pointer;
	    			@include device{
	    				@include size(43px);
	    				top: calc(50% - 43px);
	    				left: calc(50% - 22px);
	    			}
	    			&:before{
	    				content: "";
	    				@include size(25px);
	    				border-right: 3px solid white;
	    				border-top: 3px solid white;
	    				@include rotate(45deg);
	    				margin-left: 22px;
	    				margin-top: 29px;
	    				display: block;
	    				@include device{
		    				@include size(15px);
		    				margin-left: 10px;
		    				margin-top: 12px;
		    			}
	    			}
	    		}
	    		.video-cover-text{
	    			position: absolute;
	    			top: calc(50% + 60px);
	    			text-align: center;
	    			width: 100%;
	    			@include fs(38);
	    			text-transform: uppercase;
	    			font-weight: 300;
	    			@include device{
	    				@include fs(27);
	    				top: calc(50% + 10px);
	    			}
	    		}
	    		&.added-video{
	    			.video-cover-image, .icon-play, .video-cover-text{
	    				display: none;
	    			}
	    		}
	    	}
		}
	}
	.slick-dots {
		bottom: -50px;
		li {
			button{
				&:before{
					color: white;
					@include border-radius(10px);
					border: 2px solid white;
					@include size(10px);
					content: "";
					opacity: 1;
				}
			}
			&.slick-active{
				button:before{
					background: white;
				}
			}
		}
	}
	.slick-dotted.slick-slider{
		padding-bottom: 30px;
		margin-bottom: 0;
		@include device{
			padding-bottom: 0px;
		}
	}
	.slick-prev, .slick-next{
		top: auto;
		bottom: -80px;
		@include size(45px);
		@include border-radius(50%);
		border: 2px solid white;
		opacity: 0.5;
		z-index: 10;
	}

	.product-gallery{
		.slick-prev, .slick-next{
			bottom: auto;
			top: 50%;
		}
		.slick-prev{
			left: 0;
			right: auto;
		}
		.slick-next{
			left: auto;
			right: 0;
		}
		.slick-dots{
			bottom: -40px;
		}
	}
	.slick-prev{
		left: calc(50% - 160px);
		&:before{
		  content: "";
		  @include size(15px);
		  border-left: 2px solid white;
		  border-top: 2px solid white;
		  display: block;
		  @include rotate(-45deg);
		  margin-left: 17px;
		}
	}
	.slick-next{
		right: calc(50% - 160px);
		&:before{
			content: "";
		  @include size(15px);
		  border-right: 2px solid white;
		  border-top: 2px solid white;
		  display: block;
		  @include rotate(45deg);
		  margin-left: 10px;
		}
	}
	.slick-arrow.slick-disabled{
		opacity: 0;
		cursor: unset;
		&:before{
			opacity: 0;
		}
	}
	.specifications{
		padding-bottom: 80px;
		.panel-heading{
			.panel-title{
				@include fs(42);
				text-transform: uppercase;
				font-weight: 300;
				text-align: center;
				padding: 40px 0;
			}
		}
		.panel-body{
			.btn{
				width: 100%;
				margin-bottom: 20px;
				white-space: normal;
				i{
					margin-right: 10px;
				}
			}
		}
		.spec-wrapper{
			margin-top: 60px;
			.spec-group{
				margin: 0 0 60px;
				.spec-group--title{
					margin: 0 0 27px;
					@include fs(16);
					color: $secondary;
					font-weight: 600;
				}
				dl{
					border-top: 1px solid rgba(103,106,108,0.5);
					color: #fff;
					line-height: 1;
					margin: 0;
					padding: 18px 0 15px;
					display: flex;
					flex-direction: row;
					&:nth-child(2){
						border-top: 1px solid white;
					}
					dt{
						flex-grow: 1;
						font-weight: 300;
						@include fs(16);
					}
					dd{
						font-weight: bold;
						margin-left: 15px;
						@include fs(16);
					}
					&.long-text{
						flex-wrap: wrap;
						dd, dt{
							width: 100%;
							margin-left: 0;
						}
						dd{
							margin-top: 10px;
							line-height: 20px;
						}
					}
				}
			}
		}
	}
	.mobile-tab-heading{
		.mobile-tab-title{
			@include fs(16);
		    display: block;
		    font-weight: bold;
		    line-height: 1;
		    margin: 0;
		    padding: 20px 20px;
		    position: relative;
		    text-transform: uppercase;
		    transform: translateZ(0);
		    background: $gray-darker2;
		    color: white;
		    border-top: 1px solid rgba(103,106,108,0.5);
		    &:after{
		    	@include transition(0.5s);
			    @include rotate(135deg)
			    color: $red;
			    content: "";
			    position: absolute;
			    right: 20px;
			    top: 35%;
			    width: 12px;
			    height: 12px;
			    border-top: 2px solid $red;
			    border-right: 2px solid $red;
			    display: block;
		    }
		}
		&.active{
			.mobile-tab-title{
			    color: #cf1430;
			    background: #fff;
				&:after{
			    	@include rotate(315deg);
			    	top: 45%;
			    }
		    }
		}
	}
	.mobile-tab-body{
		@include desktop{
			display: block!important;
		}
	}
}